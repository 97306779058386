.btn-group button {
    margin: 5px;
    padding: 10px 20px;
    font-size: 1rem;
    line-height: 1.2;
    border: 1px solid transparent;
    display: inline-block;
    vertical-align: middle;
    box-shadow: none;
    outline: none;
    transition: font-size 0.2s ease, transform 0.2s ease;
    /* Smooth size change */
    width: 150px;
    /* Fixed width */
    height: 50px;
    /* Fixed height */
    text-align: center;
    white-space: nowrap;
    border-radius: 5px;
    /* Slight rounding */
}

.btn-primary {
    background-color: #007bff;
    color: white;
}

.btn-secondary {
    background-color: #6c757d;
    color: white;
}

.btn-group button:active {
    font-size: 1.5rem;
    /* Larger font size when clicked */
    transform: scale(1.2);
    /* Slightly increase size for visual feedback */
}